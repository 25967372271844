import { createFilenameTour } from "../../../data/repository/picture.repo";

export const CreateFilenameUseCase = async (filename) => {
  try {
    const resp = await createFilenameTour(filename);
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
