import { baseFcm, baseNotification } from "../base/baseUrl";

const TEST_KEY_NOTIFICATION =
  "AAAAh8avivo:APA91bGWP80AF5EcN1mZZMWuoWd9_PsYFmdYkgj3a1iojmgxH4skWEVeHvC6bapiHl174Ze_VvN9JuD2U1Eo5Ilo5Gv8yu9qo0kCWO1-3HT5_cV4iCwa5LF5Wj069gcYOspop0RwZde88";

export const sendPushNotification = async (driverToken, title, body) => {
  const notification = {
    registration_ids: driverToken,
    data: {
      title: title,
      body: body,
    },
    priority: "high",
    ttl: "4500s",
  };

  return await baseFcm.post("fcm/send", JSON.stringify(notification), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `key=${TEST_KEY_NOTIFICATION}`,
    },
  });
};

export const sendNotificationToken = async (token, title, body) => {
  return await baseNotification.post(
    "api/v1/send",
    JSON.stringify({ token, title, body }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendNotificationTokens = async (tokens, title, body) => {
  return await baseNotification.post(
    "api/v1/tokens",
    JSON.stringify({ tokens, title, body }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendNotificationTopic = async (topic, title, body) => {
  return await baseNotification.post(
    "api/v1/topic",
    JSON.stringify({ topic, title, body }),
    { headers: { "Content-Type": "application/json" } }
  );
};
