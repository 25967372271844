import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import {
  ProtectedDashboardRoute,
  ProtectedRoute,
} from "./components/protected/auth/ProtectedRoute";
import Login from "./views/home/login";
import { DashboardAdmin } from "./views/users/admin/Dashboard";
import ListBookNow from "./views/users/admin/booking/ListBookNow";
import AddBooking from "./views/users/admin/booking/AddBooking";
import AddDriver from "./views/users/admin/driver/AddDriver";
import AddVehicle from "./views/users/admin/vehicle/AddVehicle";
import ListClients from "./views/users/admin/client/ListClients";
import Client from "./views/users/admin/client/Client";
import BookCalendar from "./views/users/admin/booking/BookCalendar";
import QuoteBookingLater from "./views/users/admin/booking/QuoteBookingLater";
import AddQuote from "./views/users/admin/booking/AddQuote";
import ListBookLater from "./views/users/admin/booking/ListBookLater";
import AddBookingLater from "./views/users/admin/booking/AddBookingLater";
import ListDrivers from "./views/users/admin/driver/ListDrivers";
import Driver from "./views/users/admin/driver/Driver";
import Vehicle from "./views/users/admin/vehicle/Vehicle";
import Listvehicles from "./views/users/admin/vehicle/ListVehicles";
import HistoryListBookingNow from "./views/users/admin/booking/history/ListBookingNow";
import ClientHistory from "./views/users/admin/client/ClientHistory";
import GlobalConfiguration from "./views/users/admin/config/GlobalConfiguration";
import EmployeeList from "./views/users/admin/employee/employee/EmployeeList";
import EnterpriseList from "./views/users/admin/employee/EnterpriseList";
import EnterpriseEdit from "./views/users/admin/employee/EnterpriseEdit";
import AddressAdd from "./views/users/admin/employee/Address/AddressAdd";
import EmployeeAdd from "./views/users/admin/employee/employee/EmployeeAdd";
import RoleAdd from "./views/users/admin/employee/subrole/RoleAdd";
import EmployeeEdit from "./views/users/admin/employee/employee/EmployeeEdit";
import ListBookingBusiness from "./views/users/admin/booking/business/ListBookingBusiness";
import AddBookingBusiness from "./views/users/admin/booking/business/AddBookingBusiness";
import HistoryListBookingBusiness from "./views/users/admin/booking/history/ListBookingBusiness";
import DriverHistory from "./views/users/admin/driver/DriverHistory";
import InvoiceBooking from "./views/users/admin/booking/history/InvoiceBooking";
import CreditBooking from "./views/users/admin/booking/history/CreditBooking";
import RoleEdit from "./views/users/admin/employee/subrole/RoleEdit";
import ListBookBusinessLater from "./views/users/admin/booking/ListBookBusinessLater";
import QuoteBookingBusinessLater from "./views/users/admin/booking/QuoteBookingBusinessLater";
import AddBookingQuoteBusiness from "./views/users/admin/booking/business/AddBookingQuoteBusiness";
import BookingTab from "./views/users/admin/booking/BookingTab";
import AddBookingBusinessLater from "./views/users/admin/booking/business/later/AddBookingBusinesLater";
import UpdateBookingCredit from "./views/users/admin/booking/business/UpdateBookingCredit";
import DriverHistoryFixed from "./views/users/admin/driver/DriverHistoryFixed";
import ReportManifest from "./views/users/admin/report/manifest/ReportManifest";
import EnterpriseHistory from "./views/users/admin/employee/EntepriseHistory";
import { AddPermissions } from "./views/users/admin/admin/permisions/AddPermisions";
import { ListAdmin } from "./views/users/admin/admin/listAdmin";
import AddAdmin from "./views/users/admin/admin/AddAdmin";
import { ListCompany } from "./views/users/admin/admin/company/ListCompany";
import EnterpriseAdd from "./views/users/admin/employee/EnterpriseAdd";
import ListBookBusinessNow from "./views/users/admin/booking/ListBookingBusinessNow";
import RatesList from "./views/users/admin/rates/RatesList";
import RateList from "./views/users/admin/rates/rate/RateList";
import AddQuoteHour from "./views/users/admin/booking/AddQuoteHour";
import Tour from "./views/users/admin/tour/Tour";
import QuoteTour from "./views/users/admin/booking/client/tour/views/quote/QuoteTour";
import ListNewDriver from "./views/users/admin/driver/new/ListNewDriver";
import Notification from "./views/users/admin/admin/notification/ui/Notification";
import EnterpriseRatesAdd from "./views/users/admin/employee/rates/EnterpriseRatesAdd";
import EnterpriseRouteAdd from "./views/users/admin/employee/rates/EnterpriseRouteAdd";
import Recharge from "./views/users/admin/recharge/Recharge";
import Bonus from "./views/users/admin/bonus/Bonus";
import DriverRate from "./views/users/admin/driver/rate/DriverRate";
import ViajesArequipa from "./views/users/admin/viajesarequipa/ViajesArequipa";
import ViajesLista from "./views/users/admin/viajesarequipa/ViajesLista";
import Backup from "./views/users/admin/backup/views/Backup";
import CostCenter from "./views/users/admin/employee/costcenter/views/CostCenter";
import ListTransactions from "./views/users/admin/booking/history/openpay/ListTransactions";
import BackupOld from "./views/users/admin/backup/views/BackupOld";
import BackupRider from "./views/users/admin/backup/views/BackupRider";
import TourCreateScreen from "./module_tour/features/tour/create/TourCreateScreen";
import TourListScreen from "./module_tour/features/tour/read/TourListScreen";
import TourUpdateScreen from "./module_tour/features/tour/update/TourUpdateScreen";
import ShoppingCreateScreen from "./module_tour/features/shopping/create/ShoppingCreateScreen";
import ShoppingReadScreen from "./module_tour/features/shopping/read/ShoppingReadScreen";
import ImageCreateScreen from "./module_tour/features/image/create/ImageCreateScreen";
import CityCreateScreen from "./module_tour/features/city/crear/CityCreateScreen";
import CountryCreateScreen from "./module_tour/features/country/create/CountryCreateScreen";
import BookingCreateScreen from "./module_tour/features/booking/create/BookingCreateScreen";
import BookingListScreen from "./module_tour/features/booking/read/BookingListScreen";
import ScheduledCreateScreen from "./module_tour/features/scheduled/create/ScheduledCreateScreen";
import { ScheduledListScreen } from "./module_tour/features/scheduled/read/ScheduledListScreen";
import ScheduledEndScreen from "./module_tour/features/scheduled/read/ScheduledEndScreen";
import QuestionCreateScreen from "./module_tour/features/question/create/QuestionCreateScreen";
import CountryListScreen from "./module_tour/features/country/read/CountryListScreen";
import CountryUpdateScreen from "./module_tour/features/country/update/CountryUpdateScreen";
import ImageReadScreen from "./module_tour/features/image/read/ImageReadScreen";
import FooterCreateScreen from "./module_tour/features/footer/create/FooterCreateScreen";
import BookingUpdateScreen from "./module_tour/features/booking/update/BookingUpdateScreen";
import BookingHistoryScreen from "./module_tour/features/booking/read/BookingHistoryScreen";
import AdsScreen from "./views/users/admin/admin/advertising/screen/AdsScreen";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<ProtectedDashboardRoute />}>
            <Route path="/admin">
              <Route index element={<DashboardAdmin />} />
              <Route path="configuration" element={<GlobalConfiguration />} />
              <Route path="permissions/:id" element={<AddPermissions />} />
              <Route path="list-admin" element={<ListAdmin />} />
              <Route path="add-admin" element={<AddAdmin />} />
              <Route path="list-company" element={<ListCompany />} />
              <Route path="list-clients" element={<ListClients />} />
              <Route path="client/:id" element={<Client />} />
              <Route path="client-history/:id" element={<ClientHistory />} />
              <Route path="list-driver" element={<ListDrivers />} />
              <Route path="list-driver-new" element={<ListNewDriver />} />
              <Route path="driver/:id" element={<Driver />} />
              <Route path="driver-history/:id" element={<DriverHistory />} />
              <Route path="driver-rate/:id" element={<DriverRate />} />
              <Route
                path="driver-history-fixed/:id"
                element={<DriverHistoryFixed />}
              />
              <Route path="list-vehicle" element={<Listvehicles />} />
              <Route path="vehicle/:id" element={<Vehicle />} />
              <Route path="add-driver" element={<AddDriver />} />
              <Route path="add-vehicle" element={<AddVehicle />} />
              <Route path="list-book-now" element={<ListBookNow />} />
              <Route path="list-book-later" element={<ListBookLater />} />
              <Route
                path="list-book-business-later"
                element={<ListBookBusinessLater />}
              />
              <Route
                path="list-book-business-now"
                element={<ListBookBusinessNow />}
              />
              <Route path="list-book-quote" element={<QuoteBookingLater />} />
              <Route
                path="list-book-business-quote"
                element={<QuoteBookingBusinessLater />}
              />
              <Route path="list-tour-quote" element={<QuoteTour />} />
              <Route
                path="list-booking-business"
                element={<ListBookingBusiness />}
              />
              <Route
                path="add-booking-business/:id"
                element={<AddBookingBusiness />}
              />
              <Route
                path="history/booking-business"
                element={<HistoryListBookingBusiness />}
              />
              <Route path="add-booking/:id" element={<AddBooking />} />
              <Route path="add-quote/:client/:uuid" element={<AddQuote />} />
              <Route path="add-quote-hour/:uuid" element={<AddQuoteHour />} />
              <Route
                path="add-business-quote/:uuid"
                element={<AddBookingQuoteBusiness />}
              />
              <Route path="create-booking" element={<BookingTab />} />
              <Route path="add-employee/:id" element={<EmployeeAdd />} />
              <Route
                path="history-business/:id"
                element={<EnterpriseHistory />}
              />
              <Route path="tour" element={<Tour />} />
              <Route path="list-enterprise" element={<EnterpriseList />} />
              <Route path="add-enterprise" element={<EnterpriseAdd />} />
              <Route path="enterprise/:id" element={<EnterpriseEdit />} />
              <Route path="list-employee/:id" element={<EmployeeList />} />
              <Route path="employee/:id" element={<EmployeeEdit />} />
              <Route path="address/:id" element={<AddressAdd />} />
              <Route
                path="enterprise/rate/add/:id/:name"
                element={<EnterpriseRatesAdd />}
              />
              <Route
                path="enterprise/route/add/:id/:name"
                element={<EnterpriseRouteAdd />}
              />
              <Route path="address" element={<RatesList />} />
              <Route path="rutas-global" element={<RateList />} />
              <Route path="roles/:id" element={<RoleAdd />} />
              <Route path="centro-costo/:id" element={<CostCenter />} />
              <Route
                path="roles/edit/:id/:enterprise_id"
                element={<RoleEdit />}
              />
              <Route
                path="history/booking-now"
                element={<HistoryListBookingNow />}
              />
              <Route path="history/openpay" element={<ListTransactions />} />
              <Route
                path="add-booking-later/:id/:uuid"
                element={<AddBookingLater />}
              />
              <Route
                path="add-booking-business-later/:uuid"
                element={<AddBookingBusinessLater />}
              />
              <Route
                path="history/booking-invoice"
                element={<InvoiceBooking />}
              />
              <Route
                path="history/booking-credit"
                element={<CreditBooking />}
              />
              <Route
                path="credit-booking/edit/:id"
                element={<UpdateBookingCredit />}
              />
              <Route path="booking-calendar" element={<BookCalendar />} />
              {/* REPORTES */}
              <Route
                path="report-manifest/:id/:order_number"
                element={<ReportManifest />}
              />
              <Route path="notification" element={<Notification />} />

              {/* ADS */}
              <Route path="anuncios" element={<AdsScreen />} />

              {/* RECHARGE and BONUS*/}
              <Route path="recharge" element={<Recharge />} />
              <Route path="bonus" element={<Bonus />} />
              <Route path="viajesarequipa" element={<ViajesArequipa />} />
              <Route path="viajes" element={<ViajesLista />} />
              {/* BACKUP */}
              <Route path="backup-empresas" element={<Backup />} />
              <Route path="backup-clientes" element={<BackupRider />} />
              <Route path="backup-old" element={<BackupOld />} />

              {/* MODULE_TOUR */}
              <Route path="tour/lista" element={<TourListScreen />} />
              <Route path="tour/crear" element={<TourCreateScreen />} />
              <Route
                path="tour/actualizar/:id"
                element={<TourUpdateScreen />}
              />

              <Route path="shopping/crear" element={<ShoppingCreateScreen />} />
              <Route path="shopping/lista" element={<ShoppingReadScreen />} />

              <Route path="imagen/crear" element={<ImageCreateScreen />} />
              <Route path="imagen/lista" element={<ImageReadScreen />} />

              <Route path="pais/crear" element={<CountryCreateScreen />} />
              <Route path="pais/lista" element={<CountryListScreen />} />
              <Route
                path="pais/actualizar/:id"
                element={<CountryUpdateScreen />}
              />

              <Route path="ciudad/crear" element={<CityCreateScreen />} />
              {/* <Route path="ciudad/lista" element={<CityCreateScreen />} /> */}

              <Route path="reservas/crear" element={<BookingCreateScreen />} />
              <Route path="reservas/lista" element={<BookingListScreen />} />
              <Route
                path="reservas/finalizados"
                element={<BookingHistoryScreen />}
              />
              <Route
                path="reservas/actualizar/:id"
                element={<BookingUpdateScreen />}
              />

              <Route
                path="programados/crear"
                element={<ScheduledCreateScreen />}
              />
              <Route
                path="programados/lista"
                element={<ScheduledListScreen />}
              />
              <Route
                path="programados/confirmados"
                element={<ScheduledEndScreen />}
              />

              <Route
                path="preguntas/crear"
                element={<QuestionCreateScreen />}
              />

              <Route path="footer/crear" element={<FooterCreateScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
