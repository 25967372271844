import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { ref, onValue, update, remove, get } from "firebase/database";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Grid,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Stack,
  Card,
  Typography,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";

import * as yup from "yup";
import { tokens } from "../../../../theme";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  createBookingNow,
  updateDataBookingNow,
} from "../../../../api/booking/bookingNow";
import { getDriverAndVehicle } from "../../../../api/users/driver/driver";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { formatterTimePicker } from "../../../../helpers/date/formatterTimePicker";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
import { sendNotificationToken } from "../../../../api/fcm/notification";

const FormBooking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const [commissionOption, setCommissionOption] = useState(true);
  const [commissionValue, setCommissionValue] = useState(0);
  const [offers, setOffers] = useState([]);
  const [activePhone, setActivePhone] = useState(true);
  const handleActivePhone = (event) => setActivePhone(event.target.checked);

  const dbRef = ref(db, `bookingNow/${id}`);
  const dbRefOffers = ref(db, `CounterOffer/Normal`);

  useEffect(() => {
    onValue(dbRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return console.log("No Booking");
      setBooking(data);
    });
  }, []);

  useEffect(() => {
    onValue(dbRefOffers, (snapshot) => {
      const data = snapshot.val();
      setOffers([]);
      if (data !== null) {
        Object.values(data).map((_book) => {
          if (_book.idBooking === id) {
            setOffers((oldArray) => [...oldArray, _book]);
          }
        });
      }
    });
  }, []);

  const [dateformat, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");
  useEffect(() => {
    if (booking) {
      if (booking.driver) {
        const driver = {
          brand: booking.driver.brand,
          color: booking.driver.color,
          driver_id: booking.driver.driver_id,
          lastname: booking.driver.lastname,
          model: booking.driver.model,
          name: booking.driver.name,
          bodywork: booking.driver.bodywork,
          plate: booking.driver.plate,
          vehicle_id: booking.driver.vehicle_id,
          percentageValue: booking.driver.percentageValue,
        };
        setCommissionValue(driver.percentageValue);
        setDriver(driver);
      }
      setActivePhone(booking.activePhone ? booking.activePhone : true);
      setDateFormat(dayjs(booking.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(booking.date, booking.time));
    }
  }, [booking]);

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  const checkoutSchema = yup.object().shape({});

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleMouseDownPassword = (e) => e.preventDefault();

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.driver = driver;
    values.statusService = driver ? "ASIGNADO" : "PENDIENTE";
    values.statusPayment = "ASIGNADO";
    values.activePhone = activePhone;
    try {
      if (!commissionOption) {
        values.driver.percentageValue = commissionValue;
      }
      if (driver) {
        const token = "asdf";
        values.commission = values.driver.percentageValue;
        values.date = values.date.$d ? dateFormat(values.date.$d) : values.date;
        values.time = values.time.$d ? timeFormat(values.time.$d) : values.time;
        if (!values.isCreated) {
          if (values.payment_type === 2) values.statusBooking = "PAGADO";
          else values.statusBooking = "PENDIENTE";

          values.assigned = false;
          await createBookingNow(token, values);
          values.isCreated = true;

          const dbRefToken = ref(db, `Token/Driver/${driver.driver_id}`);
          get(dbRefToken)
            .then(async (snapshot) => {
              const data = snapshot.val();
              if (data != null) {
                const token = data.token;
                const title = "PEDIDO PARA AHORA CLIENTES";
                const body = "Pedido asignado";
                await sendNotificationToken(token, title, body);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          await updateDataBookingNow(token, values);
        }
        await update(ref(db, `bookingNow/${values.uuid}`), values);
        setIsLoading(false);
      } else {
        await update(ref(db, `bookingNow/${values.uuid}`), values);
        setIsLoading(false);
      }
      snackBarDialog("Pedido Actualizado");
      setTimeout(() => {
        navigate("/admin/list-book-now");
      }, 5000);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("Error: No Actualizado", "error");
    }
  };

  const asignDriver = async (idDriver) => {
    try {
      await update(dbRef, { idDriver: Number(idDriver) });
      await deleteOthersOffers();
      //mensaje push???
      const dbRefToken = ref(db, `Token/Driver/${idDriver}`);
      get(dbRefToken)
        .then(async (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            const token = data.token;
            const title = "PEDIDO ACEPTADO";
            const body = "Pedido Aceptado";
            await sendNotificationToken(token, title, body);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      snackBarDialog("RESERVA ASIGNADA A CONDUCTOR");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ASIGNAR EL PEDIDO", "error");
    }
  };

  const deleteOthersOffers = async () => {
    try {
      Object.values(offers).map(async (_book) => {
        const dbRefDel = ref(db, `CounterOffer/Normal/${_book.idBooking}`);
        await remove(dbRefDel);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {booking ? (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={booking}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box backgroundColor={colors.primary[400]} p={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={values.origin.name}
                      fullWidth
                      variant="outlined"
                      type="text"
                      label={t("origin")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="origin"
                      color="success"
                    />
                  </Grid>
                  {values.intermediate ? (
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={
                          values.intermediate[0]
                            ? values.intermediate.map((inter) => {
                                return inter.service + ", ";
                              })
                            : null
                        }
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={t("Intermediate")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="destination"
                        sx={{ gridColumn: "span 2" }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={values.destination.name}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={t("destination")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="destination"
                    />
                  </Grid>

                  <Grid item xs={11} md={6}>
                    <TextField
                      value={values.clientName}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={t("client")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="clientName"
                      InputProps={{
                        endAdornment: (
                          <Checkbox
                            style={{ marginRight: -15 }}
                            checked={activePhone}
                            onChange={handleActivePhone}
                            color="success"
                          />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              label={t("date")}
                              inputFormat="DD/MM/YYYY"
                              name="date"
                              value={dateformat}
                              onChange={(newValue) => {
                                setDateFormat(newValue);
                                values.date = newValue;
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={6} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileTimePicker
                              label={t("time")}
                              value={timeFormatter}
                              name="time"
                              onChange={(newValue) => {
                                setTimeFormat(newValue);
                                values.time = newValue;
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <FormControl fullWidth>
                          <InputLabel>{t("Model")}</InputLabel>
                          <Select
                            value={values.vehicle}
                            id="vehicle"
                            name="vehicle"
                            label={t("vehicle")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("SEDAN")}</MenuItem>
                            <MenuItem value={2}>{t("SUV")}</MenuItem>
                            <MenuItem value={3}>{t("MINIVAN")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <TextField
                          value={values.persons}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("persons")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="persons"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      value={values.note}
                      fullWidth
                      variant="outlined"
                      color="success"
                      type="text"
                      label={t("notes")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="note"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    {drivers ? (
                      <Autocomplete
                        value={driver}
                        disablePortal
                        name="driver"
                        id="combo-box-driver"
                        onChange={(e, newValue) => {
                          setDriver(newValue);
                        }}
                        options={drivers}
                        getOptionLabel={(option) =>
                          `${option.driver_id}: ${option.name} ${option.lastname} - ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("driver")} />
                        )}
                      />
                    ) : (
                      <div>loading ...</div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel>{t("comprobante")}</InputLabel>
                          <Select
                            value={values.payment_voucher}
                            id="payment_voucher"
                            name="payment_voucher"
                            label={t("comprobante")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("RECIBO")}</MenuItem>
                            <MenuItem value={2}>{t("N/A")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">
                            COMISION
                          </InputLabel>
                          <OutlinedInput
                            value={commissionValue}
                            name="commissionValue"
                            onChange={(e) => {
                              setCommissionValue(e.target.value);
                            }}
                            disabled={commissionOption}
                            id="control-dni"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  onClick={(e) => {
                                    setCommissionOption(!commissionOption);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="COMISION"
                            aria-describedby="outlined-helper-text"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel>{t("Modeda")}</InputLabel>
                          <Select
                            value={values.currency}
                            id="currency"
                            name="currency"
                            label={t("Moneda")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("PEN")}</MenuItem>
                            <MenuItem value={2}>{t("USD")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel>{t("Tipo Pago")}</InputLabel>
                          <Select
                            value={values.payment_type}
                            id="payment_type"
                            name="payment_type"
                            label={t("payment")}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("EFECTIVO")}</MenuItem>
                            <MenuItem value={2}>{t("CREDITO")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.tax}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("tax")}
                          onChange={handleChange}
                          name="tax"
                          inputProps={{ style: { fontSize: 14 } }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.toll}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("toll")}
                          onChange={handleChange}
                          name="toll"
                          inputProps={{ style: { fontSize: 14 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={3} md={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={12} md={12}>
                        <TextField
                          value={values.price}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("Precio Conductor")}
                          onChange={handleChange}
                          name="price"
                          inputProps={{ style: { fontSize: 14 } }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={12} height={45}>
                        <TextField
                          value={values.total}
                          fullWidth
                          variant="outlined"
                          color="success"
                          type="number"
                          label={t("Precio Total")}
                          onChange={handleChange}
                          name="total"
                          inputProps={{ style: { fontSize: 14 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {offers.length > 0 ? (
                      Object.values(offers).map((_book, index) => (
                        <Fragment key={index}>
                          <Card>
                            <Typography variant="h8" component="div">
                              {_book.nameDriver} {_book.bodywork} {_book.plate}{" "}
                              {_book.phoneDriver}, PEN:{_book.priceOffer} -
                              {_book.timeArrived}{" "}
                              <Button
                                color="secondary"
                                variant="contained"
                                disabled={isLoading}
                                onClick={() => asignDriver(_book.idDriver)}
                              >
                                {t("ASIGNAR")}
                              </Button>
                            </Typography>
                          </Card>
                        </Fragment>
                      ))
                    ) : (
                      <Card>
                        <Typography variant="h5" textAlign={"center"}>
                          NO HAY OFERTAS DE CONDUCTORES
                        </Typography>
                      </Card>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large"
                      sx={{ height: "100%" }}
                      disabled={isLoading}
                    >
                      GUARDAR CAMBIOS
                    </Button>
                  </Grid>
                </Grid>
                <SnackBar
                  stateSnk={stateSnk}
                  vertical={vertical}
                  horizontal={horizontal}
                  duration={4000}
                  handleClose={handleClose}
                  severity={severity}
                  messageSnk={messageSnk}
                />
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <span>loading ...</span>
      )}
    </>
  );
};

export default FormBooking;
