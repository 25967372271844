export const labelColor = { color: "white" };
export const BoxEdit = {
  background: "white",
  color: "black",
};

export const HoraryButton = {
  height: "100%",
  width: "100%",
  color: "white",
  border: "none",
  borderRadius: "5px",
};

export const ButtomStyle = { width: "200px", height: "40px" };

export const TextNumber = {
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
  "&:hover > .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
};
