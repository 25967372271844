import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SnackBar from "../../../../components/snackbar/SnackBar";
import "./card.css";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";

import LanguageIcon from "@mui/icons-material/Language";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteTourUseCase } from "../../../domain/usecases/tour/DeleteTourUseCase";

const CardTour = ({ tour }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tourOpen, setTourOpen] = useState(false);
  const handleTourOpenModal = () => setTourOpen(true);
  const handleTourCloseModal = () => setTourOpen(false);
  const [dateDisable, setDateDisable] = useState(tour.open_date);

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 3,
  };

  const tourId = tour.id;
  const redirectTo = () => {
    console.log("update");
    navigate(`/admin/tour/actualizar/${tourId}`);
  };

  const textStyle = {
    fontWeight: "bold",
    color: "black",
  };

  const buttonStyle = {
    color: "white",
    backgroundColor: "success",
    fontWeight: "bold",
    fontSize: "12px",
  };

  const deleteTour = async () => {
    try {
      const response = await DeleteTourUseCase(tourId);
      if (response.message === "success") {
        handleClose();
        snackBarDialog("TOUR ELIMINADO");
      } else {
        handleClose();
        snackBarDialog("ERROR: NO SE ELIMINAR TOUR", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const language = {
    1: "ES",
    2: "EN",
  };
  const tourType = {
    1: "TOUR",
    2: "TRASLADO",
    3: "PACK",
  };

  const enableTour = async () => {
    try {
      const tourEnable = {
        id: tour.id,
        openTour: !Boolean(tour.open_tour),
        openDate: !Boolean(tour.open_tour) ? "" : dateDisable,
      };
      // const resp = await EnableTourUseCase(tourEnable);
      // console.log(resp);

      // if (resp.message === "success") snackBarDialog("CAMBIOS REALIZADOS");
      // else snackBarDialog("ERROR: NO SE LOGRO CAMBIAR", "error");
      // handleTourCloseModal();
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO CAMBIAR", "error");
      handleTourCloseModal();
    }
  };

  const handleDateChange = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, "");

    if (value.length > 2) {
      value = value.replace(/^(\d{2})(\d{0,2})/, "$1/$2");
    }
    if (value.length > 5) {
      value = value.replace(/^(\d{2})\/(\d{2})(\d{0,4})/, "$1/$2/$3");
    }

    setDateDisable(value);
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: "white",
          color: "black",
          margin: "1px",
          height: 200,
        }}
      >
        <CardContent sx={{ height: "150px", p: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ ...textStyle, fontSize: 13 }} gutterBottom>
              {tour.title}
            </Typography>

            <div
              style={{
                ...textStyle,
                fontSize: 15,
                color: "blue",
                display: "flex",
              }}
            >
              <a
                style={{
                  fontSize: 11,
                  background: "red",
                  textAlign: "center",
                  padding: 2,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  margin: 2,
                  borderRadius: 4,
                }}
              >
                {tourType[tour.tour_type_id]}
              </a>
              <a
                style={{
                  fontSize: 11,
                  background: "blue",
                  textAlign: "center",
                  padding: 2,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  margin: 2,
                  borderRadius: 4,
                }}
              >
                {language[tour.language_id]}
              </a>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{}}>
              <Typography sx={{ ...textStyle, fontSize: 13 }}>
                {tour.code}
              </Typography>
              <Typography sx={{ ...textStyle, fontSize: 13 }}>
                {tour.city_name}
              </Typography>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: 10,
              }}
            >
              {tour.price_show === 0 && <Person2OutlinedIcon />}{" "}
              {tour.price_show === 1 && <Diversity3OutlinedIcon />}{" "}
              {tour.price_show === 2 && (
                <>
                  <Person2OutlinedIcon />
                  <Diversity3OutlinedIcon />
                </>
              )}
              <TipsAndUpdatesIcon
                sx={{
                  cursor: "pointer",
                  fill: Boolean(tour.open_tour) ? "green" : "yellow",
                }}
                onClick={handleTourOpenModal}
              />
              <Tooltip
                title={Boolean(tour.published) ? "PUBLICADO" : "BORRADOR"}
              >
                {Boolean(tour.published) ? (
                  <LanguageIcon sx={{ fill: "green" }} />
                ) : (
                  <EditIcon sx={{ fill: "red" }} />
                )}
              </Tooltip>
            </div>
          </div>
          <Typography
            sx={{
              fontSize: 12,
              height: "75px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {tour.description_text}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            color="success"
            sx={{ ...buttonStyle }}
            fullWidth
            onClick={redirectTo}
          >
            EDITAR
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{ ...buttonStyle }}
            fullWidth
            onClick={handleOpen}
          >
            ELIMINAR
          </Button>
        </CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"black"}
          >
            DESEA ELIMINAR: {tour.title}
          </Typography>
          <div>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ ...buttonStyle, width: "50%" }}
              onClick={deleteTour}
            >
              ELIMINAR
            </Button>
            <Button
              size="small"
              variant="contained"
              fullWidth
              color="success"
              sx={{ ...buttonStyle, width: "50%" }}
              onClick={handleClose}
            >
              CANCELAR
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal open={tourOpen} onClose={handleTourCloseModal}>
        <Box sx={style}>
          <Typography
            id="title"
            variant="h6"
            component="h2"
            color={"black"}
            sx={{ fontWeight: "bold" }}
          >
            Tour: <span>{tour.title}</span>
          </Typography>
          <Typography sx={{ color: "black", fontWeight: "bold" }}>
            Desea: {Boolean(tour.open_tour) ? "DESHABILITAR" : "HABILITAR"} ?
          </Typography>
          {Boolean(tour.open_tour) && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  value={dateDisable}
                  name="dateDisable"
                  label="Fecha (opcional)"
                  InputProps={{
                    style: { color: "black" },
                  }}
                  InputLabelProps={{
                    style: { color: "gray" },
                  }}
                  onChange={handleDateChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          <div style={{ marginTop: 5 }}>
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ ...buttonStyle, width: "50%" }}
              onClick={enableTour}
            >
              CONFIRMAR
            </Button>
            <Button
              size="small"
              variant="contained"
              fullWidth
              color="success"
              sx={{ ...buttonStyle, width: "50%" }}
              onClick={handleTourCloseModal}
            >
              CANCELAR
            </Button>
          </div>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default CardTour;
