import { v4 as uuidv4 } from "uuid";

export const CreateTourModel = (formData) => {
  const settings = {
    titlePage: formData.titlePage,
    metaList: formData.metaList,
  };
  return {
    id: formData.id,
    uuid: uuidv4(),
    url: formData.url,
    title: formData.title,
    code: formData.code,
    descriptionText: formData.descriptionText,
    countryId: formData.country?.countryId ?? 0,
    cityId: formData.city?.cityId ?? 0,
    priceSharePen: formData.priceSharePen,
    priceShareUsd: formData.priceShareUsd,
    pricePrivPen: formData.pricePrivPen,
    pricePrivUsd: formData.pricePrivUsd,
    priceShow: formData.priceShow,
    typeTour: formData.typeTour?.id ?? 1,
    language: formData.language,
    search: formData.search,
    settings,
    horaryList: formData.horaryList,
    defOrigin: formData.defOrigin,
    defDestination: formData.defDestination,
    sectionList: formData.sectionList,
    published: formData.published,
    // urlImage: {
    //   main: formData.urlImage.main,
    //   destination: formData.urlImage.destination,
    //   activity: formData.urlImage.activity,
    //   showDestination: formData.urlImage.showDestination,
    //   showActivity: formData.urlImage.showActivity,
    // },
    image: formData.image,
  };
};
