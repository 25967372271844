import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../theme";
import LineChart from "../../../../components/chart/LineChart";

const GridLine = ({ title, week, month, year }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [age, setAge] = useState(10);
  const [data, setData] = useState(week);

  const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value === 10) {
      setData(week);
    } else if (event.target.value === 20) {
      setData(month);
    } else if (event.target.value === 30) {
      setData(year);
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box backgroundColor={colors.primary[400]}>
            <Box
              mt="10px"
              p="0 20px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  {title}
                </Typography>
              </Box>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    labelId="chart-small"
                    id="chart-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>SEMANA</MenuItem>
                    <MenuItem value={20}>MESES</MenuItem>
                    <MenuItem value={30}>AÑOS</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box height="300px" sx={{ margin: "0 40px 0 10px" }}>
              <LineChart data={data} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridLine;

{
  /* <Box
        gridColumn="span 8"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
      >
        
        <Box height="250px" m="-20px 0 0 0"></Box>
      </Box> */
}
