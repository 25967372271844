import { createPictureList } from "../../../data/repository/picture.repo";

export const CreatePictureListUseCase = async (filename, picture) => {
  try {
    const resp = await createPictureList(filename, picture);
    return resp.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
