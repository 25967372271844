import React from "react";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";

const ImageStackS = ({ data, handleImageChange }) => {
  return (
    <Grid container spacing={1} marginTop={1}>
      <Grid item xs={12} sm={3}>
        <TextField
          value={data.url}
          fullWidth
          variant="outlined"
          type="text"
          onChange={handleImageChange}
          label={`URL ${data.label}`}
          InputLabelProps={{ style: { color: "white" } }}
          name="url"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          value={data.title}
          fullWidth
          variant="outlined"
          type="text"
          onChange={handleImageChange}
          label={"TITULO"}
          InputLabelProps={{ style: { color: "white" } }}
          name="title"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          value={data.alt}
          fullWidth
          variant="outlined"
          type="text"
          onChange={handleImageChange}
          label={"ALT"}
          InputLabelProps={{ style: { color: "white" } }}
          name="alt"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          value={data.description}
          fullWidth
          variant="outlined"
          type="text"
          onChange={handleImageChange}
          label={"DESCRIPCION"}
          InputLabelProps={{ style: { color: "white" } }}
          name="description"
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <FormControlLabel
          control={
            <Checkbox
              color="success"
              size="small"
              checked={data.show}
              onChange={(e) => {
                const { name, checked } = e.target;
                const event = {
                  target: { name, value: checked },
                };
                handleImageChange(event);
              }}
              name="show"
            />
          }
          label="Activar"
        />
      </Grid>
    </Grid>
  );
};

export default ImageStackS;
