import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { CreatePictureListUseCase } from "../../../domain/usecases/image/CreatePictureListUseCase";
import { CreateFilenameUseCase } from "../../../domain/usecases/image/CreateFilenameUseCase";

const ImageCreateScreen = () => {
  const [pictureList, setPictureList] = useState([{ imgUrl: "" }]);
  const [fileName, setFileName] = useState("");
  const [aux, setAux] = useState("");

  const handleAddElement = () => {
    if (pictureList[pictureList.length - 1].imgUrl) {
      setPictureList([...pictureList, { imgUrl: "" }]);
      setAux("");
    } else {
      snackBarDialog("TIENE UN CAMPO DE IMAGEN SIN SELECCIONAR", "error");
    }
  };

  const handleRemoveElem = () => {
    if (pictureList.length != 1) {
      const copyList = [...pictureList];
      copyList.splice(pictureList.length - 1, 1);
      setPictureList([...copyList]);
    }
  };

  const addPicture = (value, index) => {
    pictureList[index] = {
      imgUrl: value,
    };
    setPictureList([...pictureList]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [file, setFile] = useState("");
  const [showFile, setShowFile] = useState(false);

  const checkUrlForm = () => {
    if (fileName === "")
      return snackBarDialog("INGRESE EL NOMBRE DE LA GALERIA", "error");
    if (pictureList[0].imgUrl === "")
      return snackBarDialog("INGRESE MINIMO UNA URL PARA LA GALERIA", "error");
    return true;
  };

  const handleCreatePicture = async () => {
    try {
      setIsLoading(true);

      if (showFile) {
        const resp = await CreateFilenameUseCase(file);
        if (resp.message === "success") {
          snackBarDialog("SE HA CREADO LA CARPETA PARA IMAGENES");
          setIsLoading(false);
        } else {
          snackBarDialog("ERROR NO SE LOGRO CREAR LA CARPETA", "error");
          setIsLoading(false);
        }
      } else {
        if (checkUrlForm()) {
          const resp = await CreatePictureListUseCase(fileName, pictureList);
          if (resp.message === "success") {
            snackBarDialog("IMAGENES GUARDADO CORRECTAMENTE");
            setIsLoading(false);
            setPictureList([{ imgUrl: "" }]);
          } else {
            snackBarDialog("ERROR NO SE LOGRO GUARDAR LAS IMAGENES", "error");
            setIsLoading(false);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title={"CREAR GALERIA DE IMAGENES PARA LOS TOUR"}
                subtitle=""
              />
            </Box>
            <Box display="grid" gap="10px">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={fileName}
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={(e) => setFileName(e.target.value)}
                    label="NOMBRE DE LA GALERIA DE IMAGEN"
                    InputLabelProps={{ style: { color: "white" } }}
                    name="url"
                  />
                </Grid>

                <Grid item xs={12} sm={4} textAlign={"end"}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAddElement}
                  >
                    + AGREGAR
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleRemoveElem}
                  >
                    - ELIMINAR
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {pictureList.map((picture, idx) => {
                  return (
                    <Grid key={idx} item xs={12} sm={6} md={4}>
                      <TextField
                        value={picture.imgUrl}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => addPicture(e.target.value, idx)}
                        label={`URL DE LA IMAGEN`}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="url"
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={10} sm={6}>
                  <TextField
                    value={file}
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="NOMBRE DE LA CARPETA"
                    onChange={(e) => {
                      setFile(e.target.value);
                    }}
                    InputLabelProps={{ style: { color: "white" } }}
                    name="url"
                  />
                </Grid>

                <Grid item xs={2} sm={2}>
                  <FormControlLabel
                    label="GUARDAR EN CARPETA"
                    control={
                      <Checkbox
                        color="success"
                        size="small"
                        checked={showFile}
                        onChange={(e) => {
                          const { checked } = e.target;
                          setShowFile(checked);
                        }}
                        name="show"
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleCreatePicture}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </main>
      </div>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default ImageCreateScreen;
