import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { es } from "date-fns/locale";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import { onValue, ref, update, remove, get } from "firebase/database";
import { db } from "../../../../../firebase";

import { tokens } from "../../../../../theme";
import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import {
  createBookingBusiness,
  updateBookingBusiness,
} from "../../../../../api/booking/bookingBusiness";
import {
  getDriverAndVehicle,
  getDriverById,
} from "../../../../../api/users/driver/driver";
import {
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../../helpers/token/refreshToken";
import { createInvoice } from "../../../../../api/booking/Invoice";
//sidebar
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as themeStyles } from "@mui/material/styles";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import { formatterTimePicker } from "../../../../../helpers/date/formatterTimePicker";
import { dateFormat, timeFormat } from "../../../../../helpers/date/date";
import {
  sendNotificationToken,
  sendPushNotification,
} from "../../../../../api/fcm/notification";

const AddBookingBusiness = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const themeSidebar = themeStyles();
  const matchesSM = useMediaQuery(themeSidebar.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [offers, setOffers] = useState([]);
  const [drivers, setDrivers] = useState(null);
  const [driver, setDriver] = useState(null);
  const [commissionOption, setCommissionOption] = useState(true);
  const [commissionValue, setCommissionValue] = useState(0);
  const checkoutSchema = yup.object().shape({});

  const [activePhone, setActivePhone] = useState(true);
  const handleActivePhone = (event) => setActivePhone(event.target.checked);
  const [noteAdmin, setNoteAdmin] = useState("");

  const dbRef = ref(db, `BookingBusiness/${id}`);
  const dbRefOffers = ref(db, `CounterOffer/Business`);

  const bookingType = {
    1: "PEDIDO",
    2: "RESERVA",
    3: "POR HORA",
  };

  useEffect(() => {
    onValue(dbRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return console.log("No Booking");
      data.bookingType = bookingType[data.booking_type];
      setBooking(data);
    });
  }, []);

  useEffect(() => {
    onValue(dbRefOffers, (snapshot) => {
      const data = snapshot.val();
      setOffers([]);
      if (data !== null) {
        Object.values(data).map((_book) => {
          if (_book.idBooking === id) {
            console.log(_book);
            setOffers((oldArray) => [...oldArray, _book]);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (booking) {
      if (booking.driver) {
        const driver = {
          brand: booking.driver.brand,
          color: booking.driver.color,
          driver_id: booking.driver.driver_id,
          lastname: booking.driver.lastname,
          model: booking.driver.model,
          name: booking.driver.name,
          bodywork: booking.driver.bodywork,
          plate: booking.driver.plate,
          vehicle_id: booking.driver.vehicle_id,
          percentageValue: booking.driver.percentageValue,
        };
        setCommissionValue(driver.percentageValue);
        setDriver(driver);
      }
      setActivePhone(booking.activePhone ? booking.activePhone : true);
      setDateFormat(dayjs(booking.date, "DD/MM/YYYY").toDate());
      setTimeFormat(formatterTimePicker(booking.date, booking.time));
    }
  }, [booking]);

  useEffect(() => {
    const driversData = async () => {
      const verify = verifyExpireTokenRefresh();
      if (verify) return console.log("no token");

      const token = getTokenRefresh();
      try {
        const response = await getDriverAndVehicle(token);
        setDrivers(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    driversData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);
  const handleMouseDownPassword = (e) => e.preventDefault();

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [dateformat, setDateFormat] = useState("");
  const [timeFormatter, setTimeFormat] = useState("");

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    values.driver = driver;
    values.statusService = driver ? "ASIGNADO" : "PENDIENTE";
    values.statusPayment = values.total > 0 ? "ASIGNADO" : "PENDIENTE";
    values.activePhone = activePhone;

    try {
      if (!commissionOption) values.driver.percentageValue = commissionValue;

      values.date = values.date.$d ? dateFormat(values.date.$d) : values.date;
      values.time = values.time.$d ? timeFormat(values.time.$d) : values.time;
      if (driver) {
        const token = "asdf";
        values.commission = values.driver.percentageValue;
        if (!values.isCreated) {
          if (invoiceID) values.invoice_id = invoiceID;

          values.isCreated = true;
          values.state_payment = "PENDIENTE";
          values.statusBooking = "PAGADO";
          values.assigned = false;
          values.noteAdmin = noteAdmin;
          await createBookingBusiness(token, values);
          const dbRef = ref(db, `BookingBusiness/${values.uuid}`);
          const dbRefToken = ref(db, `Token/Driver/${driver.driver_id}`);

          const resp = await get(dbRefToken);
          if (resp.val() != null) {
            const data = resp.val();
            console.log(data);
            const token = data.token;
            const title = "PEDIDO PARA AHORA EMPRESAS";
            const body = "PEDIDO ASIGNADO";
            await sendNotificationToken(token, title, body);
          }

          await remove(dbRef);
          setInvoice(null);
        } else {
          await updateBookingBusiness(token, values);
        }
      } else {
        values.cotizar = true;
        await update(dbRef, values);
      }
      snackBarDialog("Pedido Actualizado");
      setTimeout(() => {
        navigate("/admin/list-booking-business");
      }, 5000);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      snackBarDialog("Error: No Actualizado", "error");
    }
  };

  const [invoice, setInvoice] = useState(null);
  const [igv, setIgv] = useState(0);
  const [notes, setNotes] = useState("");
  const [peaje, setPeaje] = useState(0);
  const [igvVal, setIgvVal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [counterTotal, setCounterTotal] = useState(0);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [service, setService] = useState("PAGADO");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setSubTotal(0);
    setPeaje(0);
    setCounterTotal(0);
    setOpenModal(false);
  };

  const [invoiceID, setInvoiceID] = useState(null);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: 500,
    p: 2,
  };

  const registerInvoice = async () => {
    try {
      if (!invoice)
        return snackBarDialog("INGRESE EL NUMERO DE FACTURA E IGV", "error");

      if (igv === 0) return snackBarDialog("SELECCIONE EL IGV", "error");

      const invoiceObject = {
        invoice,
        igv,
        date_issue: dateStart,
        date_expire: dateEnd,
        notes: notes.toUpperCase(),
        status: service,
      };
      const insertID = await createInvoice("", invoiceObject);
      setInvoiceID(insertID.data);
      handleCloseModal();
      snackBarDialog("FACTURA GENERADA");
    } catch (error) {
      console.log(error);
      snackBarDialog("NO SE LOGRO CREAR LA FACTURA", "error");
    }
  };

  const assignDriver = async (idDriver) => {
    try {
      await update(dbRef, { idDriver: Number(idDriver) });

      await deleteOthersOffers();
      const dbRefToken = ref(db, `Token/Driver/${idDriver}`);
      const resp = await get(dbRefToken);
      if (resp.val() != null) {
        const data = resp.val();
        const token = data.token;
        const title = "PEDIDO ACEPTADO";
        const body = "Cliente acepto el pedido";
        await sendNotificationToken(token, title, body);
      }
      snackBarDialog("RESERVA ASIGNADA A CONDUCTOR");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ASIGNAR EL PEDIDO", "error");
    }
  };

  const deleteOthersOffers = async () => {
    try {
      Object.values(offers).map(async (_book) => {
        const dbRefDel = ref(db, `CounterOffer/Business/${_book.idBooking}`);
        await remove(dbRefDel);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [searchDriver, setSearchDriver] = useState({ name: "", lastname: "" });

  const getDriver = async (idDriver) => {
    try {
      const token = getTokenRefresh();
      const response = await getDriverById(token, idDriver);

      const myDriver = response?.data;
      setSearchDriver({
        name: myDriver.name,
        lastname: myDriver.lastname,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (booking) {
      setPeaje(0);
      setIgvVal(0);
      setSubTotal(0);

      if (igv === 1) {
        setPeaje(booking.toll);
        setCounterTotal(booking.total + booking.toll);
      } else if (igv === 2) {
        setPeaje(booking.toll);
        const sub =
          Math.round(((booking.total + booking.toll) / 1.18) * 100) / 100;
        setSubTotal(sub);
        setIgvVal(Math.round((booking.total + booking.toll - sub) * 100) / 100);
        setCounterTotal(booking.total + booking.toll);
      } else if (igv === 3) {
        setPeaje(booking.toll);
        setSubTotal(booking.total + booking.toll);
        const imp =
          Math.round((booking.total + booking.toll) * 0.18 * 100) / 100;
        setIgvVal(imp);
        setCounterTotal(booking.total + booking.toll + imp);
      }
    }
  }, [igv]);

  return (
    <>
      <CssBaseline />
      <div className="app">
        {!matchesSM && <Sidebar />}
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"PEDIDOS EMPRESA"} subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              {booking ? (
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={booking}
                  validationSchema={checkoutSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box backgroundColor={colors.primary[400]} p={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12} textAlign="center">
                            <TextField
                              value={values.bookingType}
                              variant="outlined"
                              type="text"
                              label={t("Tipo de Pedido")}
                              name="booking_type"
                              color="success"
                              sx={{ fontWeight: "bold" }}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={values.origin.name}
                              fullWidth
                              variant="outlined"
                              type="text"
                              label={t("origin")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="origin"
                              color="success"
                            />
                          </Grid>
                          {values.intermediate ? (
                            <Grid item xs={12} md={6}>
                              <TextField
                                value={
                                  values.intermediate[0]
                                    ? values.intermediate.map((inter) => {
                                        return inter.service + ", ";
                                      })
                                    : null
                                }
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("Intermediate")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="destination"
                                sx={{ gridColumn: "span 2" }}
                              />
                            </Grid>
                          ) : null}
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={values.destination.name}
                              fullWidth
                              variant="outlined"
                              color="success"
                              type="text"
                              label={t("destination")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="destination"
                            />
                          </Grid>

                          <Grid item xs={12} md={5.5}>
                            <TextField
                              value={values.clientName}
                              fullWidth
                              variant="outlined"
                              color="success"
                              type="text"
                              label={t("client")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="clientName"
                              InputProps={{
                                endAdornment: (
                                  <Checkbox
                                    style={{ marginRight: -15 }}
                                    checked={activePhone}
                                    onChange={handleActivePhone}
                                    color="success"
                                  />
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={3}
                                md={values.booking_type === 3 ? 2 : 3}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={2}>
                                    <MobileDatePicker
                                      label={t("date")}
                                      inputFormat="DD/MM/YYYY"
                                      name="date"
                                      value={dateformat}
                                      onChange={(newValue) => {
                                        setDateFormat(newValue);
                                        values.date = newValue;
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={3}
                                md={values.booking_type === 3 ? 2 : 3}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <MobileTimePicker
                                      label={t("time")}
                                      value={timeFormatter}
                                      name="time"
                                      onChange={(newValue) => {
                                        setTimeFormat(newValue);
                                        values.time = newValue;
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={6} sm={3} md={3}>
                                <TextField
                                  value={values.persons}
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="number"
                                  label={t("persons")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="persons"
                                />
                              </Grid>
                              <Grid item xs={6} sm={3} md={3}>
                                <FormControl fullWidth>
                                  <InputLabel>{t("Model")}</InputLabel>
                                  <Select
                                    value={values.vehicle}
                                    id="vehicle"
                                    name="vehicle"
                                    label={t("vehicle")}
                                    onChange={handleChange}
                                  >
                                    <MenuItem value={1}>{t("SEDAN")}</MenuItem>
                                    <MenuItem value={2}>{t("SUV")}</MenuItem>
                                    <MenuItem value={3}>
                                      {t("MINIVAN")}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>

                          {values.booking_type === 3 ? (
                            <>
                              <Grid item xs={6} md={2}>
                                <TextField
                                  value={values.timeStart}
                                  name="timeStart"
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="text"
                                  label={t("Hora de Inicio")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <TextField
                                  value={values.timeEnd}
                                  name="timeEnd"
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="text"
                                  label={t("Hora de Finalizacion")}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </>
                          ) : null}

                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              value={values.note}
                              fullWidth
                              variant="outlined"
                              color="success"
                              type="text"
                              label={t("notes")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="note"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            {drivers ? (
                              <Autocomplete
                                value={driver}
                                disablePortal
                                name="driver"
                                id="combo-box-driver"
                                onChange={(e, newValue) => {
                                  setDriver(newValue);
                                }}
                                options={drivers}
                                getOptionLabel={(option) =>
                                  `${option.driver_id}: ${option.name} ${option.lastname} - ${option.brand} ${option.model} ${option.bodywork} ${option.plate} ${option.color}`
                                }
                                renderInput={(params) => (
                                  <TextField {...params} label={t("driver")} />
                                )}
                              />
                            ) : (
                              <div>loading ...</div>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={3} md={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>{t("Tipo Pago")}</InputLabel>
                                  <Select
                                    value={values.payment_type}
                                    id="payment_type"
                                    name="payment_type"
                                    label={t("Tipo Pago")}
                                    onChange={handleChange}
                                  >
                                    <MenuItem value={1}>{"EFECTIVO"}</MenuItem>
                                    <MenuItem value={2}>{"CREDITO"}</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={12} md={12}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    COMISION
                                  </InputLabel>
                                  <OutlinedInput
                                    value={commissionValue}
                                    name="commissionValue"
                                    onChange={(e) => {
                                      setCommissionValue(e.target.value);
                                    }}
                                    disabled={commissionOption}
                                    id="control-dni"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                          onClick={(e) => {
                                            setCommissionOption(
                                              !commissionOption
                                            );
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="COMISION"
                                    aria-describedby="outlined-helper-text"
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={12} md={12}>
                                <FormControl fullWidth>
                                  <InputLabel>{t("Moneda")}</InputLabel>
                                  <Select
                                    value={values.currency}
                                    id="currency"
                                    name="currency"
                                    label={t("Moneda")}
                                    onChange={handleChange}
                                  >
                                    <MenuItem value={1}>{t("PEN")}</MenuItem>
                                    <MenuItem value={2}>{t("USD")}</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={12} md={12}>
                                <TextField
                                  value={values.tax}
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="number"
                                  label={t("tax")}
                                  onChange={handleChange}
                                  name="tax"
                                  inputProps={{ style: { fontSize: 14 } }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={12} md={12}>
                                <TextField
                                  value={values.toll}
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="number"
                                  label={t("toll")}
                                  onChange={handleChange}
                                  name="toll"
                                  inputProps={{ style: { fontSize: 14 } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={12} md={12}>
                                <TextField
                                  value={values.price}
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="number"
                                  label={t("Precio Conductor")}
                                  onChange={handleChange}
                                  name="price"
                                  inputProps={{ style: { fontSize: 14 } }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={12} md={12}>
                                <TextField
                                  value={values.total}
                                  fullWidth
                                  variant="outlined"
                                  color="success"
                                  type="number"
                                  label={t("Precio Total")}
                                  onChange={handleChange}
                                  name="total"
                                  inputProps={{ style: { fontSize: 14 } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            {values.idDriver && (
                              // como obtengo los datos
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "40px",
                                  alignItems: "center",
                                  background: "#78c847",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "17px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    Aceptado Por:{" "}
                                  </span>
                                  {values.idDriver} - {searchDriver.name}{" "}
                                  {searchDriver.lastname}
                                </p>
                                <button
                                  style={{
                                    height: "30px",
                                    cursor: "pointer",
                                    padding: 1,
                                    border: "none",
                                    marginRight: "5px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    console.log(values.idDriver);
                                    getDriver(values.idDriver);
                                  }}
                                >
                                  VER NOMBRE
                                </button>
                              </div>
                            )}
                            {offers.length > 0 ? (
                              Object.values(offers).map((_book, index) => (
                                <Fragment key={index}>
                                  <Card>
                                    <Typography variant="h8" component="div">
                                      {_book.nameDriver} {_book.bodywork}{" "}
                                      {_book.plate} {_book.phoneDriver}{" "}
                                      {_book.timeArrived}{" "}
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        disabled={isLoading}
                                        onClick={() =>
                                          assignDriver(_book.idDriver)
                                        }
                                      >
                                        {t("ASIGNAR")}
                                      </Button>
                                    </Typography>
                                  </Card>
                                </Fragment>
                              ))
                            ) : (
                              <Card>
                                <Typography variant="h5" textAlign={"center"}>
                                  NO HAY OFERTAS DE CONDUCTORES
                                </Typography>
                              </Card>
                            )}
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Button
                              fullWidth
                              color="secondary"
                              variant="contained"
                              size="large"
                              sx={{ height: "100%" }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (values.payment_type === 1) {
                                  handleOpenModal();
                                } else {
                                  snackBarDialog(
                                    "SOLO PARA PEDIDOS EN EFECTIVO"
                                  );
                                }
                              }}
                            >
                              GENERAR FACTURA
                            </Button>
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Button
                              fullWidth
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              sx={{ height: "100%" }}
                              disabled={isLoading}
                            >
                              {t("saveChanges")}
                            </Button>
                          </Grid>
                        </Grid>
                        <SnackBar
                          stateSnk={stateSnk}
                          vertical={vertical}
                          horizontal={horizontal}
                          duration={4000}
                          handleClose={handleSnkClose}
                          severity={severity}
                          messageSnk={messageSnk}
                        />
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <span>loading ...</span>
              )}

              <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={styleModal} backgroundColor={colors.primary[400]}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="h5" component="h2">
                        FACTURA
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" component="h2">
                        TIPO: EFECTIVO
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <TextField
                        value={invoice}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"NRO FACTURA"}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setInvoice(e.target.value);
                        }}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="invoice"
                      />
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <FormControl fullWidth>
                        <InputLabel id="select-report">IGV</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-report"
                          id="typeReport"
                          value={igv}
                          onChange={(e) => setIgv(e.target.value)}
                          label="IGV"
                          name="igv"
                        >
                          <MenuItem value={1}>INAFECTO</MenuItem>
                          <MenuItem value={2}>AFECTO</MenuItem>
                          <MenuItem value={3}>MAS IGV</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} md={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                      >
                        <Stack spacing={2}>
                          <MobileDatePicker
                            value={dateStart}
                            label={"FECHA DE EMISION"}
                            inputFormat="dd/MM/yyyy"
                            name="dateStart"
                            onChange={(e) => {
                              setDateStart(e);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                      >
                        <Stack spacing={2}>
                          <MobileDatePicker
                            value={dateEnd}
                            label={"FECHA DE VENCIMIENTO"}
                            inputFormat="dd/MM/yyyy"
                            name="dateEnd"
                            onChange={(e) => {
                              setDateEnd(e);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        value={peaje}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"PEAJE"}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="notes"
                      />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        value={subTotal}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"SUBTOTAL"}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="notes"
                      />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        value={igvVal}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"IGV"}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="notes"
                      />
                    </Grid>

                    <Grid item xs={3} md={3}>
                      <TextField
                        value={counterTotal}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"TOTAL"}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="notes"
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        value={notes}
                        fullWidth
                        variant="outlined"
                        color="success"
                        type="text"
                        label={"DESCRIPCION"}
                        onChange={(e) => setNotes(e.target.value)}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        name="notes"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id="select-report">SERVICIO</InputLabel>
                        <Select
                          fullWidth
                          sx={{ height: 45 }}
                          labelId="select-report"
                          id="typeReport"
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                          label="SERVICIO"
                          name="service"
                        >
                          <MenuItem value={"PAGADO"}>PAGADO</MenuItem>
                          <MenuItem value={"NO PAGADO"}>NO PAGADO</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ height: 45 }}
                        onClick={async (e) => {
                          e.preventDefault();
                          await registerInvoice();
                        }}
                      >
                        GENERAR FACTURA
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        sx={{ height: 45 }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCloseModal();
                        }}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default AddBookingBusiness;
