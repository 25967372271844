import React from "react";
import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import cambayRegular from "../../../../font/CambayRegular.ttf";
import { monthList } from "../../MonthList";

Font.register({
  family: "Cambria",
  src: cambayRegular,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 5,
  },
  text: {
    color: "#000000",
    fontSize: 9,
    fontFamily: "Cambria",
    textAlign: "justify",
  },

  text2: {
    marginTop: 5,
    color: "#000000",
    fontSize: 9,
    fontFamily: "Cambria",
    textAlign: "justify",
  },
  text3: {
    marginTop: 2,
    color: "#000000",
    fontSize: 9,
    fontFamily: "Cambria",
    textAlign: "right",
  },
});

const Body = ({ data }) => {
  const paragraph1 = `Conste por el presente documento el arrendamiento operativo que celebran de una parte Servicios Generales Trans Mundo E.I.R.L. “Taxi Mundo” con RUC 20455135134 debidamente representada por su Gerente Julio Laura Chambilla, con domicilio para efectos del presente en la calle Abraham Valdelomar 126 Urb. Independencia zona A del distrito Alto Selva Alegre, Provincia y departamento de Arequipa, en adelante la EMPRESA y de la otra parte ${data.driverLastname}, ${data.driverName}, identificado con DNI Nro. ${data.driverDni} Con domicilio en: ${data.driverAddress}, AREQUIPA en calidad de propietario del vehículo marca ${data.brand} Modelo ${data.model} con placa ${data.plate} a quien en adelante se denominará PROPIETARIO AFILIADO, de acuerdo a las siguientes cláusulas:`;

  const paragraph2 = `PRIMERO: LA EMPRESA, es una persona jurídica constituida con la finalidad de brindar servicio de taxi en la modalidad de taxi estación o remisse, que cuenta con una línea telefónica y comunicaciones encriptadas para con los clientes y conductores debidamente registrada en la zona registral Nro. XII sede Arequipa, cuyo objetivo principal es la prestación de servicios especial de taxi, y que para el logro de sus objetivos requiere de unidades vehiculares en perfecto estado de conservación con equipo de comunicación.
SEGUNDO: El PROPIETARIO AFILIADO, es una persona natural propietario del vehículo mencionado en la introducción del presente documento, el cual desea dedicarlo al servicio de transporte en la modalidad de taxi estación o remisse y que ya cuenta con setare N° 000000, (nuevo) el cual se halla vigente.
TERCERO: Por el presente documento EL PROPIETARIO AFILIADO da en arrendamiento operativo de acuerdo al TUPA vigente de la Municipalidad Provincial de Arequipa y de las ordenanzas Vigentes, la unidad vehicular de su propiedad a favor de la EMPRESA, para que la unidad vehicular forme parte de la flota operativa registrada en la Municipalidad Provincial de Arequipa, para que sea usado en transporte público bajo el nombre de la EMPRESA. Por un periodo de un año renovable automáticamente, mismo que regirá desde la firma del presente documento.
CUARTO: las partes acuerdan que la EMPRESA solo permitirá que EL PROPIETARIO AFILIADO sea quien designe el conductor, de la unidad vehicular objeto del presente contrato.
QUINTO: EL PROPIETARIO AFILIADO se compromete al pago de S/. 15 SOLES (quince 00/100 soles) mensuales y 10% de todos los servicios, mismo que serán cobrados por la EMPRESA por medio de los servicios realizados con OS: el cobro de cada servicio lo hará el mismo PROPIETARIO AFILIADO o a través de un conductor que él mismo designe. El exceso de este ingreso será de exclusiva propiedad de la EMPRESA.
SEXTO: El presente arrendamiento operativo obliga a la empresa asumir responsabilidad solidaria conforme lo dispone el D.S No. 017-20009-MTC así como las ordenanzas municipales vigentes.
SÉPTIMO: LA EMPRESA utilizará el vehículo arrendado para que, bajo su nombre y con el uso de sus logotipos, preste el servicio de taxi en la Ciudad de Arequipa, de los servicios que se le asigne a través de call center (central).
OCTAVO: EL PROPIETARIO AFILIADO se obliga a dar el mantenimiento a la unidad vehicular para que pueda prestar el servicio de taxi, así como a implementar y cumplir con todo los requisitos que exija la autoridad competente. De igual manera todos los gastos que genere la unidad vehicular serán asumidos por el propietario afiliado. El presente contrato no genera relación laboral entre la empresa y el propietario afiliado. Así mismo no genera relación laboral entre la empresa y el conductor que elija el propietario afiliado para que conduzca la unidad vehicular. Objeto del presente arrendamiento.
NOVENO: LA EMPRESA mantendrá operativo todos los medios de comunicación para que pueda proporcionar asignar servicios de recojo de pasajeros así como personal para cumplir con todos los traslados de manera segura y hacerse cargo del pago de impuestos del servicio que realice el vehículo asignado por la central.
DECIMO: En caso la Empresa no cumpla con la novena cláusula y el propietario afiliado no está disponible con frecuencia como para realizar servicios o no cuenta con equipo de comunicación la empresa no se hará responsable en el monto mensual acumulado.
ONCEAVO: En caso que una de las partes no quiera la renovación automática de este contrato, deberá comunicarlo con documento con cargo de recepción y/o documento de fecha cierta, con una semana de anticipación, igual plazo y modo funciona para rescindir este.
`;
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const date = `Arequipa a los ${day} días del mes de ${monthList[
    month
  ].toLowerCase()} del año ${year}`;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{paragraph1}</Text>
      <Text style={styles.text2}>{paragraph2}</Text>
      <Text style={styles.text3}>{date}</Text>
    </View>
  );
};

export default Body;
