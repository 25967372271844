import { deleteTour } from "../../../data/repository/tour.repo";

export const DeleteTourUseCase = async (tourId) => {
  try {
    const response = await deleteTour(tourId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
