import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";

//CUSTOM
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import { redirect, useParams } from "react-router-dom";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { BoxEdit, TextNumber } from "../components/styles/TourStyle";
import { metaTypeList } from "../../../core/resources/metadataList";
import { langList } from "../../../core/resources/langList";
import { GetTourByIdUseCase } from "../../../domain/usecases/tour/GetTourByIdUseCase";
import { tourList } from "../../../core/resources/tourList";
import { UpdateTourUseCase } from "../../../domain/usecases/tour/UpdateTourUseCase";
import { Section } from "../../../data/models/Section.model";
import { GetCountryAndCityUseCase } from "../../../domain/usecases/country/GetCountryAndCityUseCase";
import ImageStackS from "../components/ImageStackS";

const TourUpdateScreen = () => {
  const { id } = useParams();
  const edDetails = useRef(null);

  //data update
  const [showData, setShowData] = useState(false);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [city, setCity] = useState();
  const [country, setCountry] = useState(null);
  const [typeTour, setTypeTour] = useState(0);
  const [priceSharePen, setPriceSharePen] = useState(0);
  const [priceShareUsd, setPriceShareUsd] = useState(0);
  const [pricePrivPen, setPricePrivPen] = useState(0);
  const [pricePrivUsd, setPricePrivUsd] = useState(0);
  const [priceShow, setPriceShow] = useState(2);
  const [languageId, setLanguageId] = useState(0);
  const [defOrigin, setDefOrigin] = useState("");
  const [defDestination, setDefDestination] = useState("");

  const [mainPath, setMainPath] = useState({
    item: "",
    label: "PORTADA",
    show: false,
    url: "",
    title: "",
    alt: "",
    description: "",
  });
  const [destinationPath, setDestinationPath] = useState({
    item: "",
    label: "DESTINATION",
    show: false,
    url: "",
    title: "",
    alt: "",
    description: "",
  });
  const [activityPath, setActivityPath] = useState({
    item: "",
    label: "ACTIVITY",
    show: false,
    url: "",
    title: "",
    alt: "",
    description: "",
  });

  const [destinationCheck, setDestinationCheck] = useState(false);
  const [activityCheck, setActivityCheck] = useState(false);
  const [searchCheck, setSearchCheck] = useState(false);

  //settings
  const [metaTitlePage, setMetaTitlePage] = useState("");
  const [indice, setIndice] = useState(0);

  const [route, setRoute] = useState("");
  const [route1, setRoute1] = useState("");
  const [route2, setRoute2] = useState("");
  const [metaList, setMetaList] = useState([
    { type: "", typeContent: "", content: "" },
  ]);

  const addItemHoraryList = () => {
    if (
      horaryList[horaryList.length - 1].name !== "" &&
      horaryList[horaryList.length - 1].description !== ""
    ) {
      setHoraryList([...horaryList, { name: "", description: "" }]);
      setRoute1("");
      setRoute2("");
    } else {
      window.alert("INGRESE TODOS LOS CAMPOS DE HORARIO");
    }
  };

  const removeItemHoraryList = (index) => {
    const list = [...horaryList];
    list.splice(index, 1);
    console.log(list);
    setHoraryList(list);
  };

  const addHoraryName = (value, idx) => {
    horaryList[idx].name = value;
  };
  const addHoraryDescription = (value, idx) => {
    horaryList[idx].description = value;
  };

  const [horaryList, setHoraryList] = useState([{ name: "", description: "" }]);
  const [tourSections, setTourSections] = useState([]);

  const handleAddSection = () => {
    if (tourSections.length === 0) {
      setTourSections([
        ...tourSections,
        new Section(tourSections.length, "", "", ""),
      ]);
    } else {
      if (
        tourSections[tourSections.length - 1].title !== "" &&
        tourSections[tourSections.length - 1].content !== ""
      ) {
        setTourSections([
          ...tourSections,
          new Section(tourSections.length, "", "", ""),
        ]);
      } else {
        window.alert("INGRESE TODOS LOS CAMPOS PARA SEGUIR AGREGANDO");
      }
    }
  };

  const handleDeleteSection = () => {
    const list = [...tourSections];
    list.splice(list.length - 1, 1);
    setTourSections(list);
  };

  const [published, setPublished] = useState(false);
  useEffect(() => {
    const getTour = async () => {
      try {
        const response = await GetTourByIdUseCase(id);
        console.log(response);
        if (response.message == "success") {
          const data = response.tour;
          const gallery = response.gallery;
          const horary = response.horary;

          const sett = JSON.parse(data.settings);
          setMetaTitlePage(sett?.titlePage);
          if (sett?.metaList) {
            setMetaList(sett?.metaList);
          }

          const sections = JSON.parse(data.tour_sections);
          setTourSections(sections);
          setHoraryList(
            horary.length == 0 ? [{ name: "", description: "" }] : horary
          );
          setTitle(data.title);
          setUrl(data.url_activity);
          setCode(data.code);
          setDescriptionText(data.description_text);

          setCountry({
            countryId: data.countryId,
            countryName: data.countryName,
            city: [
              {
                cityId: data.cityId,
                cityName: data.cityName,
              },
            ],
          });
          setCity({
            cityId: response.tour.cityId,
            cityName: response.tour.cityName,
          });

          setTypeTour(data.tour_type_id);
          setLanguageId(data.language_id);

          setPriceSharePen(data.price_share_pen);
          setPriceShareUsd(data.price_share_usd);
          setPricePrivPen(data.price_vip_pen);
          setPricePrivUsd(data.price_vip_usd);
          setPriceShow(Number(data.price_show));
          setDefOrigin(data.def_origin);
          setDefDestination(data.def_destination);
          setPublished(Boolean(data.published));
          setSearchCheck(Boolean(data.searches));

          gallery?.map((item) => {
            if (item.name == "main") {
              const main = {
                item: item.name,
                label: "PORTADA",
                show: Boolean(item.active),
                url: item.path,
                title: item.title,
                alt: item.alt,
                description: item.description,
              };
              setMainPath(main);
            }
            if (item.name == "destination") {
              const main = {
                item: item.name,
                label: "DESTINOS",
                show: Boolean(item.active),
                url: item.path,
                title: item.title,
                alt: item.alt,
                description: item.description,
              };
              setDestinationPath(main);
            }
            if (item.name == "activity") {
              const main = {
                item: item.name,
                label: "ACTIVIDADES",
                show: Boolean(item.active),
                url: item.path,
                title: item.title,
                alt: item.alt,
                description: item.description,
              };
              setActivityPath(main);
            }
          });

          setShowData(true);
        } else {
          console.log("error");
        }
      } catch (error) {
        setShowData(false);
      }
    };
    getTour();
  }, []);

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const getCountryAndCityList = async () => {
      try {
        const response = await GetCountryAndCityUseCase();
        setCountryList([]);
        if (response.message == "success") {
          setCountryList(response.countryList);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCountryAndCityList();
  }, []);

  const handleServiceAdd = () => {
    if (metaList[metaList.length - 1].content !== "") {
      setMetaList([...metaList, { type: "", typeContent: "", content: "" }]);
      setIndice(indice + 1);
      setRoute("");
      setRoute1("");
      setRoute2("");
    } else {
      window.alert("INGRESE EL CAMPO PARA SEGUIR CREANDO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...metaList];
    list.splice(index, 1);
    console.log(metaList);
    setMetaList(list);
  };

  const addServiceMeta = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: meta.type,
        description: value,
        content: meta.content,
      };
    } else {
      metaList[index].description = value;
    }
  };

  const addServiceType = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: value,
        description: meta.description,
        content: meta.content,
      };
    } else {
      metaList[index].type = value;
    }
  };

  const addServiceTypeContent = (value, meta, index) => {
    if (index == 0) {
      metaList[0] = {
        type: meta.type,
        description: meta.description,
        content: value,
      };
    } else {
      metaList[index].content = value;
    }
  };

  const handleRadioChange = (event) => {
    setPriceShow(Number(event.target.value));
  };

  const checkedTourData = () => {
    if (title == "") return alert("INGRESE EL TITULO DEL TOUR");
    if (code == "") return alert("INGRESE EL CODIGO DEL TOUR");
    if (descriptionText == "") return alert("INGRESE LA DESCRIPCION DEL TOUR");
    if (country == 0) return alert("SELECCIONE EL PAIS");
    if (city == 0) return alert("SELECCIONE LA CIUDAD");
    if (priceSharePen == 0) return alert("INGRESE EL PRECIO EN SOLES");
    if (priceShareUsd == 0) return alert("INGRESE EL PRECIO EN DOLARES");
    if (pricePrivPen == 0) return alert("INGRESE EL PRECIO EN SOLES");
    if (pricePrivUsd == 0) return alert("INGRESE EL PRECIO EN DOLARES");

    return true;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleUpdateTour = async (published) => {
    try {
      setIsLoading(true);
      const tour = {
        id,
        title,
        code,
        descriptionText,
        countryId: country.countryId,
        cityId: city.cityId,
        priceSharePen,
        priceShareUsd,
        pricePrivPen,
        pricePrivUsd,
        priceShow,
        image: [mainPath, destinationPath, activityPath],
        search: searchCheck,
        settings: { titlePage: metaTitlePage, metaList },
        typeTour,
        languageId,
        url,
        horaryList,
        defOrigin,
        defDestination,
        tourSections,
        published: published,
      };

      if (published) {
        const check = checkedTourData();
        if (check) {
          console.log(tour);
          const resp = await UpdateTourUseCase(tour);
          console.log(resp);
          if (resp.message === "success") {
            snackBarDialog("SE HA ACTUALIZADO CORRECTAMENTE");
          } else snackBarDialog("ERROR: ACTUALIZACION FALLO", "error");
        }
      } else {
        console.log(tour);
        const resp = await UpdateTourUseCase(tour);
        console.log(resp);
        if (resp.message === "success") {
          snackBarDialog("SE HA ACTUALIZADO CORRECTAMENTE");
        } else snackBarDialog("ERROR: ACTUALIZACION FALLO", "error");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackBarDialog("ERROR: ACTUALIZACION FALLO");
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const { name, value } = e.target;
    const copyImage = { ...mainPath, [name]: value };
    setMainPath({ ...copyImage });
  };
  const handleDestChange = (e) => {
    const { name, value } = e.target;
    const copyImage = { ...destinationPath, [name]: value };
    setDestinationPath({ ...copyImage });
  };
  const handleActChange = (e) => {
    const { name, value } = e.target;
    const copyImage = { ...activityPath, [name]: value };
    setActivityPath({ ...copyImage });
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={`ACTUALIZAR: ${title}`} subtitle="" />
            </Box>

            {showData && (
              <>
                <Box
                  border={2}
                  borderColor="green"
                  padding={1}
                  borderRadius={3}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={metaTitlePage}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setMetaTitlePage(e.target.value)}
                        label={"TITULO DE LA PAGINA"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="title"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          IDIOMA
                        </InputLabel>
                        <Select
                          value={languageId}
                          onChange={(e) => setLanguageId(e.target.value)}
                          label="IDIOMA"
                          name="language"
                        >
                          {langList.map((elem, index) => (
                            <MenuItem key={index} value={elem.id}>
                              {elem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {metaList?.map((service, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                              <InputLabel>META(tipo)</InputLabel>
                              <Select
                                value={
                                  metaList[index].type
                                    ? metaList[index].type
                                    : route1
                                }
                                onChange={(e) => {
                                  setRoute1(e.target.value);
                                  addServiceType(
                                    e.target.value,
                                    metaList[index],
                                    index
                                  );
                                }}
                                label={"META(tipo)"}
                                name="route1"
                              >
                                {metaTypeList.map((elem, index) => (
                                  <MenuItem key={index} value={elem.type}>
                                    {elem.type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField
                              value={
                                metaList[index].content
                                  ? metaList[index].content
                                  : route2
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute2(e.target.value);
                                addServiceTypeContent(
                                  e.target.value,
                                  metaList[index],
                                  index
                                );
                              }}
                              label={"Nombre"}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                              name="route2"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              value={
                                metaList[index].description
                                  ? metaList[index].description
                                  : route
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute(e.target.value);
                                addServiceMeta(
                                  e.target.value,
                                  metaList[index],
                                  index
                                );
                              }}
                              label={"CONTENIDO"}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                              name="route"
                              InputProps={{
                                endAdornment: (
                                  <>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={handleServiceAdd}
                                    >
                                      +
                                    </button>
                                    <button
                                      style={{ marginRight: -5 }}
                                      color="success"
                                      onClick={(e) =>
                                        handleServiceRemove(index)
                                      }
                                    >
                                      -
                                    </button>
                                  </>
                                ),
                              }}
                            />
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </Box>
                <Box
                  border={2}
                  borderColor="green"
                  padding={1}
                  borderRadius={3}
                  marginTop={"5px"}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={url}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setUrl(e.target.value)}
                        label={"URL"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="url"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={title}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        label={"TITULO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="title1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        value={code}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setCode(e.target.value)}
                        label={"CODIGO DE TOUR"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="code"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {countryList && (
                        <Autocomplete
                          value={country || ""}
                          disablePortal
                          name="country"
                          id="combo-box-country"
                          onChange={(e, newValue) => {
                            setCountry(newValue);
                          }}
                          options={countryList}
                          getOptionLabel={(option) => `${option.countryName}`}
                          renderInput={(params) => (
                            <TextField {...params} label={"PAIS"} />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.countryId === value.countryId
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {countryList && (
                        <Autocomplete
                          value={city || ""}
                          disablePortal
                          name="city"
                          id="combo-box-city"
                          onChange={(e, newValue) => {
                            setCity(newValue);
                          }}
                          options={country?.city ? country.city : []}
                          getOptionLabel={(option) => `${option.cityName}`}
                          renderInput={(params) => (
                            <TextField {...params} label={"CIUDAD"} />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.cityId === value.cityId
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel style={{ color: "white" }}>
                          TIPO DE TOUR
                        </InputLabel>
                        <Select
                          value={typeTour}
                          onChange={(e) => setTypeTour(e.target.value)}
                          label={"TIPO DE TOUR"}
                          name="typeTour"
                        >
                          {tourList.map((elem, index) => (
                            <MenuItem key={index} value={elem.id}>
                              {elem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ImageStackS
                        data={mainPath}
                        handleImageChange={handleImageChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <ImageStackS
                        data={destinationPath}
                        handleImageChange={handleDestChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <ImageStackS
                        data={activityPath}
                        handleImageChange={handleActChange}
                      />
                    </Grid>
                    {/* <Grid item xs={6} sm={3}>
                      <TextField
                        value={destinationPath}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setDestinationPath(e.target.value)}
                        label={"URL de DESTINO"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="main"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            size="small"
                            checked={destinationCheck}
                            onChange={(e) => {
                              setDestinationCheck(e.target.checked);
                            }}
                          />
                        }
                        label="Activar"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        value={activityPath}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setActivityPath(e.target.value)}
                        label={"URL de ACTIVIDAD"}
                        InputLabelProps={{ style: { color: "white" } }}
                        name="main"
                      />
                      <FormControlLabel
                        control={<Checkbox color="success" size="small" />}
                        label="Activar"
                        checked={activityCheck}
                        onChange={(e) => {
                          setActivityCheck(e.target.checked);
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={6} sm={3}>
                      <label style={{ display: "block" }}>Top Destinos</label>
                      <FormControlLabel
                        control={<Checkbox color="success" size="small" />}
                        label="Activar"
                        checked={searchCheck}
                        onChange={(e) => setSearchCheck(e.target.checked)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>
                          Descripcion Texto (para las cards)
                        </InputLabel>
                        <OutlinedInput
                          value={descriptionText}
                          name="descriptionText"
                          fullWidth
                          variant="outlined"
                          type="text"
                          onChange={(e) => setDescriptionText(e.target.value)}
                          label="Descripcion Texto (para las cards)"
                          endAdornment={
                            <InputAdornment position="end">
                              {descriptionText?.length}
                            </InputAdornment>
                          }
                          sx={TextNumber}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} textAlign={"center"}>
                      <Typography variant="h5" fontWeight={"bold"}>
                        PRECIOS
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      {(priceShow === 0 || priceShow === 2) && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              COMPARTIDO
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={priceSharePen}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={(e) => setPriceSharePen(e.target.value)}
                              label={"PEN"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="priceSharePen"
                            />
                          </Grid>

                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={priceShareUsd}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={(e) => setPriceShareUsd(e.target.value)}
                              label={"USD"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="priceShareUsd"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      {(priceShow === 1 || priceShow === 2) && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              textAlign={"center"}
                            >
                              PRIVADO
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={pricePrivPen}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={(e) => setPricePrivPen(e.target.value)}
                              label={"PEN"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="pricePrivPen"
                            />
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <TextField
                              value={pricePrivUsd}
                              fullWidth
                              variant="outlined"
                              type="number"
                              onChange={(e) => setPricePrivUsd(e.target.value)}
                              label={"USD"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="pricePrivUsd"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            fontWeight={"bold"}
                            textAlign={"center"}
                          >
                            PRECIO VISIBLE
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl>
                            <RadioGroup
                              row
                              name="radio-group-price"
                              value={priceShow}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio color="success" />}
                                label="Compartido"
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio color="success" />}
                                label="Privado"
                              />
                              <FormControlLabel
                                value={2}
                                control={<Radio color="success" />}
                                label="Ambos"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} marginTop={"10px"}>
                      {horaryList.map((horary, idx) => (
                        <Grid container spacing={1} key={idx} marginTop={"3px"}>
                          <Grid item xs={12} sm={4} md={4}>
                            <TextField
                              value={
                                horaryList[idx].name
                                  ? horaryList[idx].name
                                  : route1
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute1(e.target.value);
                                addHoraryName(e.target.value, idx);
                              }}
                              label={"TURNO"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="name"
                            />
                          </Grid>
                          <Grid item xs={10} sm={6} md={6}>
                            <TextField
                              value={
                                horaryList[idx].description
                                  ? horaryList[idx].description
                                  : route2
                              }
                              fullWidth
                              variant="outlined"
                              type="text"
                              onChange={(e) => {
                                setRoute2(e.target.value);
                                addHoraryDescription(e.target.value, idx);
                              }}
                              label={"DESCRIPCION"}
                              InputLabelProps={{ style: { color: "white" } }}
                              name="description"
                            />
                          </Grid>
                          <Grid item xs={1} sm={1} md={1}>
                            <button
                              style={{
                                height: "100%",
                                width: "100%",
                                color: "white",
                                backgroundColor: "green",
                                border: "none",
                                borderRadius: "5px",
                              }}
                              onClick={addItemHoraryList}
                            >
                              +
                            </button>
                          </Grid>
                          {horaryList.length > 1 && idx != 0 && (
                            <Grid item xs={1} sm={1} md={1}>
                              <button
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  color: "white",
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "5px",
                                }}
                                onClick={(e) => removeItemHoraryList(idx)}
                              >
                                -
                              </button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <Grid item xs={12} marginTop={"10px"}>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        ESTABLECER ORIGEN DESTINO PARA TOUR COMPARTIDO
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={defOrigin}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setDefOrigin(e.target.value)}
                        label="LUGAR DE RECOJO"
                        InputLabelProps={{ style: { color: "white" } }}
                        name="defOrigin"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={defDestination}
                        fullWidth
                        variant="outlined"
                        type="text"
                        onChange={(e) => setDefDestination(e.target.value)}
                        label="DESTINO FINAL"
                        InputLabelProps={{ style: { color: "white" } }}
                        name="defDestination"
                      />
                    </Grid>

                    <Grid item xs={12} marginBottom={1} textAlign={"end"}>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ width: { xs: "100%", sm: 200 } }}
                        onClick={(e) => handleAddSection()}
                      >
                        AGREGAR SECCION
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ width: { xs: "100%", sm: 200 } }}
                        onClick={(e) => handleDeleteSection()}
                      >
                        ELIMINAR ULTIMO
                      </Button>
                    </Grid>

                    {tourSections?.map((section, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{ marginTop: "10px", width: "100%" }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                value={section.title}
                                fullWidth
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  tourSections[idx].title = e.target.value;
                                  setTourSections((oldData) => [...oldData]);
                                }}
                                label="TITULO DEL MENU"
                                InputLabelProps={{ style: { color: "white" } }}
                                name="titleMenu"
                              />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <TextField
                                value={section.titleLong}
                                fullWidth
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  tourSections[idx].titleLong = e.target.value;
                                  setTourSections((oldData) => [...oldData]);
                                }}
                                label="TITULO DE LA SECCION"
                                InputLabelProps={{ style: { color: "white" } }}
                                name="titleSection"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box style={BoxEdit}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                  DETALLES ADICIONALES
                                </Typography>
                                <JoditEditor
                                  ref={edDetails}
                                  value={section.content}
                                  tabIndex={1}
                                  onBlur={(newContent) => {
                                    tourSections[idx].content = newContent;
                                    setTourSections((oldData) => [...oldData]);
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}

                    <Grid item xs={12} sm={12}>
                      <Box display="flex" justifyContent="end" mt="10px">
                        <Button
                          color="warning"
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateTour(published);
                          }}
                          disabled={isLoading}
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          ACTUALIZAR
                        </Button>
                        <Button
                          color="success"
                          variant="contained"
                          disabled={isLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateTour(true);
                          }}
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          PUBLICAR
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </>
  );
};

export default TourUpdateScreen;
