import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import SnackBar from "../../../../components/snackbar/SnackBar";
import ImageTable from "../components/ImageTable";
import { GetPictureListUseCase } from "../../../domain/usecases/image/GetPictureListUseCase";
import { GetPictureHasTourUseCase } from "../../../domain/usecases/image/GetPictureHasTourUseCase";
import { CreatePictureTourUseCase } from "../../../domain/usecases/image/CreatePictureTourUseCase";

const ImageReadScreen = () => {
  const [galleryList, setGalleryList] = useState([]);
  const [gallerySelect, setGallerySelect] = useState("");
  const [tourList, setTourList] = useState([]);
  const [tourSelect, setTourSelect] = useState("");
  const [pictureList, setPictureList] = useState([]);

  useEffect(() => {
    const getList = async () => {
      const resp = await GetPictureListUseCase();
      setGalleryList(resp.pictureList);
      setTourList(resp.tourList);
    };

    getList();
    getPictureListApi();
  }, []);

  const getPictureListApi = async () => {
    const picList = await GetPictureHasTourUseCase();
    if (picList.message === "success") {
      setPictureList(picList.list);
    } else {
      setPictureList([]);
    }
  };

  const handleCreateTourPicture = async () => {
    try {
      if (gallerySelect === "")
        return snackBarDialog("SELECCION LA GALERIA", "error");
      if (tourSelect === "" || tourSelect.length == 0)
        return snackBarDialog("SELECCIONE EL TOUR", "error");

      const relation = {
        picture: gallerySelect,
        tourList: tourSelect,
      };
      setIsLoading(true);
      const resp = await CreatePictureTourUseCase(relation);
      if (resp.message === "success") {
        getPictureListApi();
        snackBarDialog("GALERIA DE IMAGENES ASIGNADA CORRECTAMENTE");
        setIsLoading(false);
      } else {
        snackBarDialog("ERROR: NO SE LOGO ASIGNAR LAS IMAGENES", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const position = { vertical: "top", horizontal: "center" };

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title={"ASIGNAR GALERIA DE IMAGENES A TOUR"}
                subtitle=""
              />
            </Box>
            <Box display="grid" gap="10px">
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel style={{ color: "white" }}>
                      NOMBRE DE GALERIA
                    </InputLabel>{" "}
                    <Select
                      value={gallerySelect}
                      onChange={(e) => {
                        setGallerySelect(e.target.value);
                      }}
                      label="NOMBRE DE GALERIA"
                      name="language"
                    >
                      {galleryList.map((elem, index) => (
                        <MenuItem key={index} value={elem}>
                          {elem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={tourList}
                    getOptionLabel={(option) =>
                      `${option.code.toUpperCase()} ${option.title.toUpperCase()}`
                    }
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      setTourSelect(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Seleccione los Tour"
                        placeholder="Seleccione los Tour"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleCreateTourPicture}
                    disabled={isLoading}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <ImageTable data={pictureList} />
            </Box>
          </Box>
        </main>
      </div>

      <SnackBar
        stateSnk={stateSnk}
        vertical={position.vertical}
        horizontal={position.horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default ImageReadScreen;
